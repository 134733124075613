/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var $carousel = $("body.page-template-template-subpage .carousel.slide");

        if($carousel.length > 0){
          $carousel.after("<div class='carousel-caption-bottom'></div>");

          var imageTemplate = '<span aria-hidden="true"><img type="image/svg+xml" class="{0}" style="display: none" src="{1}/dist/images/svg/arrow_flat.svg" width="18px" height="100%" /></span>';

          $carousel.find('.carousel-control.left').html(imageTemplate.format('svg svg-stroke-dark-color rotate-180', wpTemplateUri));
          $carousel.find('.carousel-control.right').html(imageTemplate.format('svg svg-stroke-dark-color', wpTemplateUri));

          var swapCaption = function(itemIndex) {
            var caption = $('body.page-template-template-subpage .carousel.slide div.item:nth-child(' + itemIndex + ') .carousel-caption');
            $('.carousel-caption-bottom').html(caption.html());
            caption.css('display', 'none');
          };

          swapCaption(1);

          $carousel.on('slide.bs.carousel', function (evt) {
            swapCaption(($(evt.relatedTarget).index() + 1));
          });
        }

        svgReplace();

        $(window).load(function () {
          equalHeightColumns();
        });

        $(window).on('resize load', function() {
          var carouselItem = $('#myCarousel > .carousel-inner > .item.active');

          if(carouselItem.length > 0) {
            var carouselHeight = carouselItem.height();
            var carouselMaxHeight = parseInt(carouselItem.css('max-height').replace("px", ""));

            var image = $('#myCarousel > .carousel-inner > .item.active > div.img-container > img');

            var imgHeight = $(image).height();

            if (carouselHeight === carouselMaxHeight) {
              $(image).css("margin-top", -((imgHeight - carouselHeight) / 2));
            }else{
              $(image).css("margin-top", "0");
            }

            $('#myCarousel > .carousel-inner > .item').css('visibility', 'visible');
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the about us page, after the init JS
      }
    },
    'page_template_template_subpage': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the about us page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
