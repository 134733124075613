(function($) {

  $('.navbar-collapse').on('hide.bs.collapse', function () {
    $(".navbar-toggle").removeClass("nav-active");
  });

  $('.navbar-collapse').on('show.bs.collapse', function () {
    $(".navbar-toggle").addClass("nav-active");
  });

  // First, checks if it isn't implemented yet.
  if (!String.prototype.format) {
    String.prototype.format = function() {
      var args = arguments;
      return this.replace(/{(\d+)}/g, function(match, number) {
        return typeof args[number] !== 'undefined' ? args[number] : match;
      });
    };
  }

  (function($) {
    if ($.fn.style) {
      return;
    }

    // Escape regex chars with \
    var escape = function(text) {
      return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    };

    // For those who need them (< IE 9), add support for CSS functions
    var isStyleFuncSupported = !!CSSStyleDeclaration.prototype.getPropertyValue;
    if (!isStyleFuncSupported) {
      CSSStyleDeclaration.prototype.getPropertyValue = function(a) {
        return this.getAttribute(a);
      };
      CSSStyleDeclaration.prototype.setProperty = function(styleName, value, priority) {
        this.setAttribute(styleName, value);
        priority = typeof priority !== 'undefined' ? priority : '';
        if (priority !== '') {
          // Add priority manually
          var rule = new RegExp(escape(styleName) + '\\s*:\\s*' + escape(value) +
              '(\\s*;)?', 'gmi');
          this.cssText =
              this.cssText.replace(rule, styleName + ': ' + value + ' !' + priority + ';');
        }
      };
      CSSStyleDeclaration.prototype.removeProperty = function(a) {
        return this.removeAttribute(a);
      };
      CSSStyleDeclaration.prototype.getPropertyPriority = function(styleName) {
        var rule = new RegExp(escape(styleName) + '\\s*:\\s*[^\\s]*\\s*!important(\\s*;)?',
            'gmi');
        return rule.test(this.cssText) ? 'important' : '';
      };
    }

    // The style function
    $.fn.style = function(styleName, value, priority) {
      // DOM node
      var node = this.get(0);
      // Ensure we have a DOM node
      if (typeof node === 'undefined') {
        return this;
      }
      // CSSStyleDeclaration
      var style = this.get(0).style;
      // Getter/Setter
      if (typeof styleName !== 'undefined') {
        if (typeof value !== 'undefined') {
          // Set style property
          priority = typeof priority !== 'undefined' ? priority : '';
          style.setProperty(styleName, value, priority);
          return this;
        } else {
          // Get style property
          return style.getPropertyValue(styleName);
        }
      } else {
        // Get CSSStyleDeclaration
        return style;
      }
    };
  })(jQuery);

})(jQuery);

var svgReplace = function() {
  /*
   * Replace all SVG images with inline SVG
   */
  jQuery('img.svg').each(function(){
    var $img = jQuery(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');
    var imgWidth = $img.attr('width');
    var imgHeight = $img.attr('height');

    jQuery.get(imgURL, function(data) {
      // Get the SVG tag, ignore the rest
      var $svg = jQuery(data).find('svg');

      // Add replaced image's ID to the new SVG
      if(typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      }
      // Add replaced image's classes to the new SVG
      if(typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass+' replaced-svg');
      }

      // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr('xmlns:a');

      if(typeof imgWidth !== 'undefined' && typeof imgHeight !== 'undefined'){
        $svg = $svg.attr('height', imgHeight);
        $svg = $svg.attr('width', imgWidth);
      }

      // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
      if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
      }

      // Replace image with new SVG
      $img.replaceWith($svg);

    }, 'xml');
  });
};

var equalHeightColumns = function() {
  $(".row-eq-height").each(function() {
    var heights = $(this).find(".col-eq-height").map(function() {
      return $(this).outerHeight();
    }).get(), maxHeight = Math.max.apply(null, heights);

    $(this).find(".col-eq-height").outerHeight(maxHeight);
  });
};